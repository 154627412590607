
    import { mapState, mapMutations, mapActions } from 'vuex';
    import has from 'lodash/has';

    import datalayer from '@/utils/datalayer';
    import { jobData } from '@/utils/jobData';

    export default {
        name: 'ResumeWriteBottomDialogue',

        props: {
            sitePosition: {
                type: String,
                default: '',
            },

            ctaLabel: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                showPromptModal: false,
                topResumePrompt: {
                    component_name: 'TopResumePrompt',
                    title: 'Resumes',
                    code: 'topresume_prompt_more_info',
                    layout: 'lg',
                    page_referral_element: '',
                    site_position: '',
                },
            };
        },

        computed: {
            ...mapState({
                $_job: state => state.job.job,
                $_jobViewCount: state => state.resumeWrite.jobViewCount,
                $_showBottomDialogue: state => state.resumeWrite.showBottomDialogue,
                $_scoringData: state => state.me.scoringData,
            }),

            getSitePosition() {
                if (this.$route.name === 'default-search-results' && !this.$route.query.jobview) {
                    return 'search_results';
                } else {
                    return 'job_view';
                }
            },
        },

        mounted() {
            if (this.canShowBottomDialogue()) {
                this.promptEvent('dialogue_impression', 'topresume_ad_unit');
            }
        },

        methods: {
            ...mapActions('eventTracking', {
                $_pushEvent: 'pushEvent',
            }),

            ...mapMutations('resumeWrite', {
                $_DISABLE_BOTTOM_DIALOGUE: 'DISABLE_BOTTOM_DIALOGUE',
            }),

            openPromptModal() {
                this.topResumePrompt.page_referral_element = this.getSitePosition;
                this.topResumePrompt.site_position = this.getSitePosition;
                this.showPromptModal = true;
                this.promptEvent('cta_click', 'topresume_ad_unit');
                this.$_DISABLE_BOTTOM_DIALOGUE();
            },

            closeDialogueModal() {
                this.$_DISABLE_BOTTOM_DIALOGUE();
                this.promptEvent('prompt_abandoned', 'topresume_prompt_dialogue');
                this.showPromptModal = false;
            },

            closePromptModal() {
                this.$_DISABLE_BOTTOM_DIALOGUE();
                this.showPromptModal = false;
            },

            canShowBottomDialogue() {
                if (this.$route.name === 'job-preview-id') {
                    return false;
                } else if (this.$route.name === 'default-search-results' && !this.$route.query.jobview) {
                    return (this.$_jobViewCount >= 2 && this.$_showBottomDialogue !== 'false');
                } else {
                    return (this.$_jobViewCount >= 3 && this.$_showBottomDialogue === 'true');
                }
                // if (this.$route.name === 'default-search-results' && !this.$route.query.jobview) {
                //     return this.$_jobViewCount >= 2 && this.$_showBottomDialogue !== 'false';
                // } else {
                //     return this.$_jobViewCount >= 3 && this.$_showBottomDialogue === 'true';
                // }
            },

            promptEvent(eventName, eventCode) {
                let data = {};

                if (this.$_job.id) {
                    data = {
                        event: eventName,
                        code: eventCode,
                        site_position: this.sitePosition,
                        cta: this.ctaLabel,
                        seeker_match_score: this.$_job.seeker_match_score,
                        seeker_data: this.$_scoringData,
                        ...jobData(this.$_job),
                    };
                } else {
                    data = {
                        event: eventName,
                        code: eventCode,
                        site_position: this.sitePosition,
                        cta: this.ctaLabel,
                        seeker_data: this.$_scoringData,
                    };
                }
                if (has(this.$_search, 'searchPayload.search_results.jobs')) {
                    data.job_uuids = this.$_search.searchPayload.search_results.jobs.map(o => o.uuid);
                }
                if (has(this.$_search, 'searchPayload.search_filters')) {
                    data.search_filters = this.$_search.searchPayload.search_filters;
                }
                datalayer(data);
                this.$_pushEvent(data);
            },
        },
    };
