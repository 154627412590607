
    import SearchSort from '@/components/Search/SearchSort';

    export default {
        name: 'SearchResultHeader',

        components: {
            SearchSort,
        },

        props: {
            keyword: {
                type: String,
                default: '',
            },

            pagingInfo: {
                type: String,
                default: '',
            },
        },
    };
