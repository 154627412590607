
    import { faSearch as falSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
    import { faChevronDown as farChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        name: 'C1Dropdown',

        components: {
            FontAwesomeIcon,
        },

        props: {
            value: {
                type: [Object, Array],
                default: () => {},
            },

            labelKey: {
                type: String,
                default: 'label',
            },

            valueKey: {
                type: String,
                default: 'id',
            },

            excludeValueKeys: {
                type: Array,
                default: () => [],
            },

            countKey: {
                type: String,
                default: 'count',
            },

            options: {
                type: Array,
                default: () => [],
            },

            placeholder: {
                type: String,
                default: 'Choose',
            },

            hideInputIcon: {
                type: Boolean,
                default: false,
            },

            isInvalid: {
                type: Boolean,
                default: false,
            },

            hidePlaceholder: {
                type: Boolean,
                default: false,
            },

            disabled: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                arrowCounter: -1,
                isOpen: false,
                icons: {
                    falSearch,
                    farChevronDown,
                },
            };
        },

        computed: {
            classList() {
                return [
                    'c1-dropdown',
                    this.getDropdownOpenClass,
                    this.getDropdownSelectedClass,
                    this.getDropdownInvalidClass,
                ];
            },

            getDropdownInvalidClass() {
                return this.isInvalid ? 'is-invalid' : '';
            },

            getDropdownOpenClass() {
                return this.dropDownOpen ? 'c1-dropdown--open' : '';
            },

            getDropdownSelectedClass() {
                return this.value[this.valueKey] ? 'c1-dropdown--selected' : '';
            },

            dropDownOpen() {
                return this.isOpen && !!this.filteredOptions.length;
            },

            getSelected() {
                const output = this.filteredOptions.find(o => o[this.valueKey] === this.value[this.valueKey]) || {};
                return output[this.labelKey] ? output[this.labelKey] : this.placeholder;
            },

            filteredOptions() {
                if (this.excludeValueKeys.length) {
                    return this.options.filter(item => !this.excludeValueKeys.includes(item[this.valueKey]));
                }
                return this.options;
            },
        },

        watch: {
            dropDownOpen(value) {
                if (value) {
                    this.$root.$emit('c1::dropdown::shown', this);
                }
            },
        },

        created() {
            this.$root.$on('c1::dropdown::shown', this.rootCloseListener);
        },

        mounted() {
            if (typeof document !== 'undefined') {
                document.addEventListener('click', this.clickOutListener);
            }
        },

        beforeDestroy() {
            if (typeof document !== 'undefined') {
                document.removeEventListener('click', this.clickOutListener);
            }
        },

        methods: {
            clickOutListener(e) {
                // TODO Refactor as it should close only the current scope
                if (!this.$el.contains(e.target)) {
                    this.close();
                }
            },

            rootCloseListener(vm) {
                if (vm !== this) {
                    this.close();
                }
            },

            setArrowCounter(number) {
                this.arrowCounter = number;
            },

            resetArrowCounter() {
                this.arrowCounter = 0;
            },

            onFocus() {
                if (window.innerWidth < 768) {
                    this.activateMobileMode();
                }
            },

            onArrowDown() {
                if (!this.isOpen) {
                    this.open();
                }
                if (this.arrowCounter < this.filteredOptions.length - 1) {
                    this.arrowCounter += 1;
                    this.fixScrolling();
                }
            },

            onArrowUp() {
                if (this.arrowCounter >= 0) {
                    this.arrowCounter -= 1;
                    this.fixScrolling();
                }
            },

            onSelect() {
                const selectedOption = this.filteredOptions[this.arrowCounter];
                if (selectedOption) {
                    this.emitInput(selectedOption);
                } else {
                    this.emitCleanInput();
                }

                this.clear();
            },

            emitInput(option) {
                const originalOption = option ? this.filteredOptions.find(o => o[this.valueKey] === option[this.valueKey]) || {} : {};
                this.$emit('input', originalOption);
                this.emitChange();
            },

            emitCleanInput() {
                this.$emit('input', {});
                this.emitChange();
            },

            emitChange() {
                this.$emit('change');
            },

            onEsc() {
                this.close();
            },

            fixScrolling() {
                if (this.arrowCounter > 0) {
                    this.$refs[`option_${this.arrowCounter}`][0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                }
            },

            clear() {
                this.close();
                this.resetArrowCounter();
            },

            toggle() {
                this.isOpen = !this.isOpen;
            },

            open() {
                this.isOpen = true;
            },

            close() {
                this.isOpen = false;
            },

            isSelected(option) {
                return this.value[this.valueKey] === option[this.valueKey];
            },
        },
    };
