
    import dayjs from 'dayjs';
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
    import { isEmpty } from 'lodash';
    import Cookie from 'js-cookie';
    import SearchPageDefault from '@/components/SearchPageTemplates/SearchPageDefault';
    import ResumeWriteBottomDialogue from '@/components/ResumeWriting/ResumeWriteBottomDialogue';
    import SearchDataLayerEventMixin from '@/components/Search/SearchDataLayerEventMixin';

    export default {
        name: 'Index',

        layout: 'discovery',

        components: {
            SearchPageDefault,
            ResumeWriteBottomDialogue,
        },

        mixins: [SearchDataLayerEventMixin],

        async asyncData({ app, store, query, redirect, route, params }) {
            if (route.name === 'home') {
                const me = store.state.me.user;
                if (me.id) {
                    redirect(302, '/discover');
                }
            }
            await store.dispatch('search/getSearchPageMetaData', route.path).then((response) => {
                store.commit('search/SET', { key: 'pageMetaData', value: response.data });
            });

            await store.dispatch('brand/getBrandEeoTags').then((response) => {
                store.commit('search/SET', { key: 'availableEeoTags', value: response.data });
            });
        },

        data() {
            return {
                searchPayload: {},
                isListView: false,
            };
        },

        computed: {
            ...mapState({
                $_search: state => state.search,
                $_me: state => state.me.user,
            }),

            ...mapGetters('jobModal', {
                $_isJobModalOpen: 'isJobModalOpen',
            }),

            ...mapGetters({
                $_currentAppUrl: 'currentAppUrl',
            }),

            urlQuery() {
                return !isEmpty(this.$route.query)
                    ? '?' + Object.keys(this.$route.query).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this.$route.query[key])}`).join('&')
                    : '';
            },

            getSitePosition() {
                if (this.$route.name === 'default-search-results' && !this.$route.query.jobview) {
                    return 'search_results_dialogue_bottom_right';
                } else {
                    return 'job_view_dialogue_bottom_right';
                }
            },

            isHomePage() {
                return this.$route.name === 'home';
            },

            getCanonicalUrl() {
                const siteUrl = this.$siteUrl;
                const canonicalUrl = this.$_search.pageMetaData.canonical_url.replace('/', '');
                return siteUrl + canonicalUrl;
            },
        },

        watch: {
            $route(v) {
                if (v.query.view) {
                    this.detectView();
                }
            },
        },

        created() {
            this.detectView();
            this.initBottomDialogue();
        },

        mounted() {
            this.getSearchPayload();
            this.$store.commit('search/SET', { key: 'activeSearchFilter', value: false });
            this.getSearchFilterAttributes();
            this.updateMe();
        },

        methods: {
            ...mapActions('job', {
                $_searchJobs: 'searchJobs',
            }),

            ...mapActions('me', {
                $_syncScoring: 'syncScoring',
            }),

            ...mapActions('brand', {
                $_getBrandEeoTags: 'getBrandEeoTags',
            }),

            ...mapMutations('search', {
                $_SET_SEARCH_PAGE_TEMPLATE: 'SET_SEARCH_PAGE_TEMPLATE',
                $_SET: 'SET',
                $_SET_SEARCH_OBJECT: 'SET_OBJECT',
            }),

            ...mapMutations('resumeWrite', {
                $_SET_STATE_BOTTOM_DIALOGUE: 'SET_STATE_BOTTOM_DIALOGUE',
                $_SET_JOB_VIEW_COUNT: 'SET_JOB_VIEW_COUNT',
            }),

            ...mapActions('attribute', {
                $_getSearchFilterAttributes: 'getSearchFilterAttributes',
            }),

            async getSearchFilterAttributes() {
                let searchFilterAttributes = this.$_search.searchFilterAttributes;
                if (searchFilterAttributes.length <= 0) {
                    searchFilterAttributes = await this.$_getSearchFilterAttributes();
                    this.$_SET({
                        key: 'searchFilterAttributes',
                        value: searchFilterAttributes,
                    });
                }
            },

            detectView() {
                this.$_SET_SEARCH_PAGE_TEMPLATE('search_results_query_inline_courses_slider_pos_3');
                return true;
            },

            initBottomDialogue() {
                let rwShowBottomDialogue = Cookie.get('rw_show_bottom_dialogue');
                if (typeof rwShowBottomDialogue === 'undefined') {
                    rwShowBottomDialogue = 'true';
                }

                let rwJobViewCount = Cookie.get('rw_job_view_count');
                if (typeof rwShowBottomDialogue === 'undefined') {
                    rwJobViewCount = 0;
                } else {
                    rwJobViewCount = parseInt(Cookie.get('rw_job_view_count'));
                }
                this.$_SET_STATE_BOTTOM_DIALOGUE(rwShowBottomDialogue);
                this.$_SET_JOB_VIEW_COUNT(rwJobViewCount);
            },

            async getSearchPayload() {
                let url = '';
                if (this.isHomePage) {
                    url = this.$jobSearchDefaultUrlQuery;
                } else {
                    url = this.$route.fullPath;
                }
                const allowBackFill = url.search('/br_') <= 0;
                this.searchPayload = await this.$_searchJobs({
                    url,
                    locale: 'AU',
                    allow_backfill: allowBackFill,
                }).then().catch((e) => {
                    if (e.name === 'TypeError [ERR_UNESCAPED_CHARACTERS]' || e.response.status === 404 || e.response.status === 422) {
                        const url = `${this.$_currentAppUrl}/not-found`;
                        this.$route.redirect(url);
                    }
                    return true;
                });

                this.$_SET_SEARCH_OBJECT({
                    key: 'searchPayload',
                    value: this.searchPayload,
                });

                this.jobSearchDataLayerPush();
                this.jobSearchEventPush();
            },

            async updateMe() {
                if (this.$_me.id) {
                    await this.$_syncScoring();
                }
            },
        },

        head() {
            return {
                title: `${this.$_search.pageMetaData.meta_title || 'Jobs'} - ${dayjs().format('MMM YYYY')} | ${this.$siteName}`,
                meta: [
                    { hid: 'og:title', property: 'og:title', content: this.$_search.pageMetaData.meta_title?.replace('CareerOne', this.$siteName) },
                    { hid: 'description', name: 'description', content: this.$_search.pageMetaData.meta_description?.replace('CareerOne', this.$siteName) },
                    { hid: 'og:description', property: 'og:description', content: this.$_search.pageMetaData.meta_description?.replace('CareerOne', this.$siteName) },
                    { hid: 'robots', name: 'robots', content: this.$_search.pageMetaData.robots },
                ],
                link: [
                    { hid: 'canonical', rel: 'canonical', href: this.getCanonicalUrl },
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: '{\n' +
                            '  "@context": "http://schema.org",\n' +
                            '  "@type": "WebPage",\n' +
                            '  "mainEntityOfPage": {\n' +
                            '    "@type": "SearchResultsPage",\n' +
                            `    "name": "${this.$_search.pageMetaData.meta_title || 'CareerOne'}",\n` +
                            `    "description": "${this.$_search.pageMetaData.meta_description || ''}",\n` +
                            `    "lastReviewed": "${(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)).toISOString()}"\n` +
                            '  }\n' +
                            '}',
                    },
                ],
                bodyAttrs: {
                    class: [
                        'bg-c1-light-400',
                        this.$_isJobModalOpen ? 'overflow-hidden' : '',
                        this.$_search.isSearchFullscreenMode ? 'body--with-fullscreen-component' : '',
                    ],
                },
            };
        },
    };
