
    import LazyHydrate from 'vue-lazy-hydration';
    import SearchMixin from '@/components/Search/SearchMixin';
    import SearchResultHeader from '@/components/Search/SearchResultHeader';
    import JobViewModalMixin from '@/components/JobViewModal/JobViewModalMixin';
    import SearchJobList from '@/components/Search/SearchJobList';
    import SearchJobListEmptyState from '@/components/Search/SearchJobListEmptyState';

    export default {
        name: 'SearchPageDefault',

        components: {
            LazyHydrate,
            SearchResultHeader,
            SearchJobList,
            SearchJobListEmptyState,
            SearchMessage: () => import(/* webpackChunkName: "HeaderNonLoggedIn" */'@/components/Search/SearchMessage'),
            Pagination: () => import(/* webpackChunkName: "HeaderNonLoggedIn" */'@/components/Utils/Pagination'),
            AdUnit: () => import(/* webpackChunkName: "HeaderNonLoggedIn" */'@/components/Ad/AdUnit'),
            EducationSlideWidget: () => import(/* webpackChunkName: "EducationSlideWidget" */'@/components/Education/EducationSlideWidget'),
            RelatedSearchTabs: () => import(/* webpackChunkName: "RelatedSearchTabs" */'@/components/Search/RelatedSearch'),
            GetJobMatchesOnboarding: () => import(/* webpackChunkName: "GetJobMatchesOnboarding" */'@/components/Utils/GetJobMatchesOnboarding'),
            SearchbarDetailed: () => import(/* webpackChunkName: "SearchbarDetailed" */'@/components/Search/Searchbars/SearchbarDetailed'),
            SearchbarStacked: () => import(/* webpackChunkName: "SearchbarStacked" */'@/components/Search/Searchbars/SearchbarStacked'),
        },

        mixins: [SearchMixin, JobViewModalMixin],
    };
