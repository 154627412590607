
    export default {
        name: 'Shimmer',

        props: {
            customWidth: {
                type: String,
                default: 'calc(100% - 30px);',
            },
            customHeight: {
                type: String,
                default: '24px',
            },
        },

        data() {
            return {
                shimmerStyles: {
                    width: this.customWidth,
                    height: this.customHeight,
                },
            };
        },
    };
