
    import Shimmer from '@/components/Utils/Shimmer';

    export default {
        name: 'SearchJobListEmptyState',

        components: {
            Shimmer,
        },
    };
