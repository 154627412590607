
    import { mapState, mapMutations } from 'vuex';
    import C1Dropdown from '@/C1Components/C1Dropdown';

    export default {
        name: 'SearchSort',

        components: {
            C1Dropdown,
        },

        data() {
            return {
                sortOptions: [
                    { id: 1, label: 'Relevance', value: 'relevance' },
                    { id: 2, label: 'Date posted', value: 'activates_at' },
                ],
            };
        },

        computed: {
            ...mapState({
                $_search: state => state.search,
            }),

            getSortBy: {
                get() {
                    const sortByValue = this.$_search.searchPayload.search_filters.sort_by;
                    if (!sortByValue || sortByValue === '' || sortByValue === 'relevance') {
                        return this.sortOptions[0];
                    } else {
                        return this.sortOptions[1];
                    }
                },

                set(selectedItem) {
                    this.$_SET_SEARCH_FILTERS({ key: 'sort_by', value: selectedItem.value });
                },
            },
        },

        methods: {
            ...mapMutations('search', {
                $_SET_SEARCH_FILTERS: 'SET_SEARCH_FILTERS',
            }),
        },
    };
